import React, { Component } from "react";
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography,
} from "react-simple-maps";
import { scaleLinear } from "d3-scale";
import ReactTooltip from "react-tooltip";
import { Card, Button, Progress } from "antd";
import _ from "lodash";
import numeral from "numeral";

const ButtonGroup = Button.Group;

class CompaniesMapDistribution extends Component {
  constructor(props) {
    super(props);

    this.state = {
      zoom: 1,
    };

    this.handleZoomIn = this.handleZoomIn.bind(this);
    this.handleZoomOut = this.handleZoomOut.bind(this);
  }

  handleZoomIn() {
    if (this.state.zoom < 8)
      this.setState({
        zoom: this.state.zoom * 2,
      });
  }

  handleZoomOut() {
    if (this.state.zoom > 0.5)
      this.setState({
        zoom: this.state.zoom / 2,
      });
  }

  componentDidMount() {
    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 1000);
  }

  render() {
    const { loading, data } = this.props;

    const maxDataValue = _(data).values().max();

    const colorScale = scaleLinear()
      .domain([0, maxDataValue])
      .range(["#e0ebfd", "#001434"]);

    return (
      <Card title="Companies Distribution" loading={loading}>
        <div style={{ position: "relative" }}>
          <ButtonGroup style={{ position: "absolute", bottom: 15, right: 5 }}>
            <Button
              type="primary"
              size="small"
              icon="plus"
              onClick={this.handleZoomIn}
              data-tip={"Zoom In"}
            />
            <Button
              type="primary"
              size="small"
              icon="minus"
              onClick={this.handleZoomOut}
              data-tip={"Zoom Out"}
            />
          </ButtonGroup>
          <ComposableMap
            projectionConfig={{
              scale: 110,
              rotation: [-11, 0, 0],
            }}
            width={400}
            height={300}
            style={{ width: "100%", height: "auto" }}
          >
            <ZoomableGroup zoom={this.state.zoom}>
              <Geographies geography={"/other/countries-110m.json"}>
                {(geographies, projection) =>
                  geographies.map((geography, i) => {
                    //   console.log(geography.properties.name);
                    //   console.log(data[geography.properties.name] || 0);
                    return (
                      <Geography
                        key={geography.properties.name}
                        data-tip={
                          geography.properties.name +
                          ": " +
                          (numeral(data[geography.properties.name]).format(
                            "0,0"
                          ) || 0)
                        }
                        geography={geography}
                        projection={projection}
                        style={{
                          default: {
                            fill: colorScale(
                              data[geography.properties.name] || 0
                            ),
                            stroke: "#FFF",
                            strokeWidth: 0.5,
                            outline: "none",
                          },
                          hover: {
                            fill: "#fde0e0",
                            stroke: "#711b1b",
                            strokeWidth: 0.75,
                            outline: "none",
                          },
                        }}
                      />
                    );
                  })
                }
              </Geographies>
              {/* <Markers>
            <Marker />
          </Markers>
          <Lines>
            <Line />
          </Lines>
          <Annotation /> */}
            </ZoomableGroup>
          </ComposableMap>
          <ReactTooltip />
        </div>
        <div>
          {Object.keys(data)
            .sort(function (a, b) {
              return data[b] - data[a];
            })
            .slice(0, 5)
            .map((key) => (
              <div style={{ margin: "5px 0px" }} key={key}>
                <span>{key}</span>
                <span style={{ float: "right", fontWeight: "600" }}>
                  {numeral(data[key]).format("0,0")}
                </span>
                <Progress
                  percent={(data[key] * 100.0) / maxDataValue}
                  showInfo={false}
                  strokeColor={{
                    "0%": "#ff4d4f",
                    "100%": "#1890ff",
                  }}
                />
              </div>
            ))}
        </div>
      </Card>
    );
  }
}

export default CompaniesMapDistribution;
