import React, { Component } from "react";
import { Table, Card, message, Tag, Button } from "antd";
import axios from "axios";
import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";
// import _ from "lodash";
const { getName } = require("country-list");

const CancelToken = axios.CancelToken;

const columns = [
  {
    title: "Company",
    dataIndex: "company",
    key: "company",
    fixed: "left",
    width: 320,
  },
  {
    title: "Registeration Date",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 150,
    defaultSortOrder: "descend",
    sorter: (a, b) => a.createdAtValue - b.createdAtValue,
  },
  {
    title: "Employees",
    dataIndex: "employees",
    key: "employees",
    sorter: (a, b) => a.employees - b.employees,
    width: 150,
  },
  {
    title: "Users",
    dataIndex: "users",
    key: "users",
    sorter: (a, b) => a.users - b.users,
    width: 150,
  },
  {
    title: "Transactions",
    dataIndex: "transactions",
    key: "transactions",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.transactions - b.transactions,
    width: 150,
  },
  {
    title: "Contact Name",
    dataIndex: "contactName",
    key: "contactName",
    width: 200,
  },
  {
    title: "Contact Email",
    dataIndex: "contactEmail",
    key: "contactEmail",
    width: 200,
  },
  {
    title: "Contact Phone",
    dataIndex: "contactPhone",
    key: "contactPhone",
    width: 200,
  },
  {
    title: "Country",
    dataIndex: "country",
    key: "country",
    width: 200,
  },
  {
    title: "Verified",
    dataIndex: "verified",
    key: "verified",
    width: 150,
    sorter: (a, b) => a.verifiedValue - b.verifiedValue,
  },
  {
    title: "Linked Deviced",
    dataIndex: "linkedDevices",
    key: "linkedDevices",
    width: 150,
    sorter: (a, b) => a.linkedDevices - b.linkedDevices,
  },
  {
    title: "Last Transaction Date",
    dataIndex: "lastTransaction",
    key: "lastTransaction",
    sorter: (a, b) => a.lastTransactionValue - b.lastTransactionValue,
  },
  {
    title: "Action",
    key: "action",
    // eslint-disable-next-line react/display-name
    render: (_text, record) => {
      return !record.verifiedValue ? (
        <Button
          type="primary"
          block
          onClick={() => record.setCompanyStatus(true)}
        >
          Activate
        </Button>
      ) : (
        <Button
          type="danger"
          block
          onClick={() => record.setCompanyStatus(false)}
        >
          Deactivate
        </Button>
      );
    },
  },
];

class TableReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      csvData: [],
      loading: false,
    };
    this.axiosCancelFetchData = null;
  }

  fetchData = (startDate, endDate) => {
    this.setState({
      data: [],
      loading: true,
    });

    axios
      .get(process.env.REACT_APP_API_ROOT + "/tableReport", {
        headers: {
          Authorization: localStorage.getItem("jwt"),
        },
        cancelToken: new CancelToken((c) => {
          this.axiosCancelFetchData = c;
        }),
      })
      .then(
        function (response) {
          let csvData = [
            [
              "Company",
              "Plan",
              "Registeration Date",
              "Employees",
              "Users",
              "Transactions",
              "Contact Name",
              "Contact Email",
              "Contact Phone",
              "Country",
              "Verified",
              "Linked Devices",
              "Last Transaction Date",
            ],
          ];

          response.data.forEach((e, k) => {
            e.users = e.users ? e.users : {};
            csvData.push([
              e.companyName,
              e.plan,
              moment(e.createdAt).format("DD MMM, YYYY"),
              e.employeesCount,
              e.usersCount,
              e.transactionCount,
              (e.users?.firstName + " " + e.users?.lastName).trim(),
              e.users.email,
              e.users.phone,
              getName(e.country),
              e.isActive ? "Yes" : "No",
              e.linkedDevices,
              e.lastTransactionDate == null
                ? "N/A"
                : moment(e.lastTransactionDate).format("DD MMM, YYYY"),
            ]);
          });

          this.setState({
            data: response.data.map((e, k) => {
              e.users = e.users ? e.users : {};
              return {
                key: e._id,
                createdAt: moment(e.createdAt).format("DD MMM, YYYY"),
                createdAtValue: moment(e.createdAt).unix(),
                company: (
                  <React.Fragment>
                    <span>{e.companyName}</span>{" "}
                    {e.plan === "Free" ? (
                      <Tag color="#ff8042">Free</Tag>
                    ) : e.plan === "Professional" ? (
                      <Tag color="#0088fe">Professional</Tag>
                    ) : e.plan === "Enterprise" ? (
                      <Tag color="#00c49f">Enterprise</Tag>
                    ) : (
                      <Tag color="#000">Unknown</Tag>
                    )}
                  </React.Fragment>
                ),
                employees: e.employeesCount,
                users: e.usersCount,
                transactions: e.transactionCount,
                contactName: (
                  e.users?.firstName +
                  " " +
                  e.users?.lastName
                ).trim(),
                contactEmail: e.users.email,
                contactPhone: e.users.phone,
                country: getName(e.country),
                verified: (
                  <React.Fragment>
                    {e.isActive ? (
                      <Tag color="#87d068">Yes</Tag>
                    ) : (
                      <Tag color="#f50">No</Tag>
                    )}
                  </React.Fragment>
                ),
                verifiedValue: e.isActive ? 1 : 0,
                linkedDevices: e.linkedDevices,
                lastTransaction:
                  e.lastTransactionDate == null
                    ? "N/A"
                    : moment(e.lastTransactionDate).format("DD MMM, YYYY"),
                lastTransactionValue:
                  e.lastTransactionDate == null
                    ? 0
                    : moment(e.lastTransactionDate).unix(),
                setCompanyStatus: (status) => {
                  axios
                    .post(
                      process.env.REACT_APP_API_ROOT + "/updateCompanyStatus",
                      {
                        companyId: e._id,
                        status,
                      },
                      {
                        headers: {
                          Authorization: localStorage.getItem("jwt"),
                        },
                        cancelToken: new CancelToken((c) => {
                          this.axiosCancelFetchData = c;
                        }),
                      }
                    )
                    .then((res) => {
                      this.fetchData();
                    })
                    .catch((_e) => {
                      // TODO: Handle Error
                    });
                },
              };
            }),
            loading: false,
            csvData: csvData,
          });
        }.bind(this)
      )
      .catch((e) => {
        console.log(e);
        if (!axios.isCancel(e)) {
          message.error("Could not fetch Table Report data");
          message.error(e);
        }
      });
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.startDate !== prevProps.startDate ||
      this.props.endDate !== prevProps.endDate
    ) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    if (typeof this.axiosCancelFetchData === "function")
      this.axiosCancelFetchData();
  }

  render() {
    const { loading, data, csvData } = this.state;

    return (
      <Card
        title="Companies Report"
        loading={loading}
        extra={<CSVLink data={csvData}>Download CSV</CSVLink>}
      >
        <Table
          dataSource={data}
          columns={columns}
          pagination={{ pageSize: 100 }}
          scroll={{ x: 2300, y: 460 }}
        />
      </Card>
    );
  }
}

export default TableReport;
