import React, { Component } from "react";
import EngagingCompaniesTable from "./EngagingCompaniesTable";
import { message, Tag } from "antd";
import axios from "axios";
// import _ from "lodash";

const CancelToken = axios.CancelToken;

class EngagingCompaniesTableWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
    };
    this.axiosCancelFetchData = null;
  }

  fetchData = (startDate, endDate) => {
    this.setState({
      data: [],
      loading: true,
    });

    axios
      .get(
        process.env.REACT_APP_API_ROOT + "/dashboard/mostEngagingCompanies",
        {
          params: {
            "period[start]": startDate.toDate(),
            "period[end]": endDate.toDate(),
          },
          headers: {
            Authorization: localStorage.getItem("jwt"),
          },
          cancelToken: new CancelToken((c) => {
            this.axiosCancelFetchData = c;
          }),
        }
      )
      .then(
        function (response) {
          this.setState({
            data: response.data.map((e, k) => ({
              key: e._id,
              company: (
                <React.Fragment>
                  <span>{e.companyName}</span>{" "}
                  {e.plan === "Free" ? (
                    <Tag color="#ff8042">Free</Tag>
                  ) : e.plan === "Professional" ? (
                    <Tag color="#0088fe">Professional</Tag>
                  ) : e.plan === "Enterprise" ? (
                    <Tag color="#00c49f">Enterprise</Tag>
                  ) : (
                    <Tag color="#000">Unknown</Tag>
                  )}
                </React.Fragment>
              ),
              users: e.usersCount,
              employees: e.employeesCount,
              transactions: e.transactionsCount,
            })),
            loading: false,
          });
        }.bind(this)
      )
      .catch((e) => {
        if (!axios.isCancel(e)) {
          message.error("Could not fetch Most Engaging Companies data");
        }
      });
  };

  componentDidMount() {
    const { startDate, endDate } = this.props;
    this.fetchData(startDate, endDate);
  }

  componentDidUpdate(prevProps) {
    const { startDate, endDate } = this.props;

    if (
      this.props.startDate !== prevProps.startDate ||
      this.props.endDate !== prevProps.endDate
    ) {
      this.fetchData(startDate, endDate);
    }
  }

  componentWillUnmount() {
    if (typeof this.axiosCancelFetchData === "function")
      this.axiosCancelFetchData();
  }

  render() {
    return (
      <EngagingCompaniesTable
        data={this.state.data}
        loading={this.state.loading}
      />
    );
  }
}

export default EngagingCompaniesTableWrapper;
