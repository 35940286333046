import React, { Component } from "react";
import { Table, Card } from "antd";

const columns = [
  {
    title: "Company",
    dataIndex: "company",
    key: "company",
  },
  {
    title: "Employees",
    dataIndex: "employees",
    key: "employees",
    // defaultSortOrder: "descend",
    sorter: (a, b) => a.employees - b.employees,
  },
  {
    title: "Users",
    dataIndex: "users",
    key: "users",
    // defaultSortOrder: "descend",
    sorter: (a, b) => a.users - b.users,
  },
  {
    title: "Transactions",
    dataIndex: "transactions",
    key: "transactions",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.transactions - b.transactions,
  },
];

class EngagingCompaniesTable extends Component {
  render() {
    const { loading } = this.props;

    return (
      <Card title="Most Engaging Companies" loading={loading}>
        <Table
          dataSource={this.props.data}
          columns={columns}
          pagination={{ pageSize: 5 }}
        />
      </Card>
    );
  }
}

export default EngagingCompaniesTable;
