import React, { Component } from "react";
import { Form, Icon, Input, Button } from "antd";

class ResetPasswordStep extends Component {
  state = {
    loading: false,
    fieldsDisabled: false,
  };

  doResetPassword = () => {
    this.setState({ loading: true, fieldsDisabled: true });
    setTimeout(() => {
      this.setState({ loading: false, fieldsDisabled: false });
      //   message.error("Invalid email or password.");
      this.props.next();
    }, 2000);
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmit} className="reset-password-form">
        {/* <h1 style={{ textTransform: "uppercase" }}>Admin Login</h1> */}
        <Form.Item>
          {getFieldDecorator("password", {
            rules: [{ required: true, message: "Please enter a new password" }],
          })(
            <Input.Password
              prefix={<Icon type="key" style={{ color: "rgba(0,0,0,.25)" }} />}
              disabled={this.state.fieldsDisabled}
              placeholder="Password"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("confirm-password", {
            rules: [
              { required: true, message: "Please enter the password again" },
            ],
          })(
            <Input.Password
              prefix={<Icon type="key" style={{ color: "rgba(0,0,0,.25)" }} />}
              disabled={this.state.fieldsDisabled}
              placeholder="Confirm Password"
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button
            type="danger"
            htmlType="submit"
            loading={this.state.loading}
            onClick={this.doResetPassword}
            className="reset-password-form-button"
          >
            Save Password
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default ResetPasswordStep;
