import React, { Component } from "react";
import { Steps, Icon, Form } from "antd";
import EnterEmailStep from "./EnterEmailStep";
import VerificationStep from "./VerificationStep";
import ResetPasswordStep from "./ResetPasswordStep";
// import { withRouter } from "react-router-dom";
// import { useHistory } from "react-router";

const { Step } = Steps;

class ForgetPasswordSteps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };

    const EnterEmailStepANT = Form.create({ name: "normal_login" })(
      EnterEmailStep
    );
    const VerificationStepANT = Form.create({ name: "normal_login" })(
      VerificationStep
    );
    const ResetPasswordStepANT = Form.create({ name: "normal_login" })(
      ResetPasswordStep
    );

    this.forgetPasswordSteps = [
      {
        title: "Enter Email",
        icon: <Icon type="mail" />,
        component: <EnterEmailStepANT next={this.next} />,
      },
      {
        title: "Verification",
        icon: <Icon type="solution" />,
        component: <VerificationStepANT next={this.next} prev={this.prev} />,
      },
      {
        title: "Reset Password",
        icon: <Icon type="key" />,
        component: (
          <ResetPasswordStepANT
            next={() => {
              this.props.history.push("/login");
            }}
          />
        ),
      },
    ];
  }

  next = () => {
    const current = this.state.current + 1;
    this.setState({ current });
  };

  prev = () => {
    const current = this.state.current - 1;
    this.setState({ current });
  };

  render() {
    const loadingIcon = <Icon type="loading" />;
    return (
      <div>
        <Steps current={this.state.current} className="forgetpassword-steps">
          {this.forgetPasswordSteps.map((singleStep, key) => (
            <Step
              key={singleStep.title}
              title={singleStep.title}
              icon={key === this.state.current ? loadingIcon : singleStep.icon}
            />
          ))}
        </Steps>
        {this.forgetPasswordSteps[this.state.current].component}
      </div>
    );
  }
}

export default ForgetPasswordSteps;
