import React, { Component } from "react";
import SubscriptionDistributionChart from "./SubscriptionDistributionChart";
import { message } from "antd";
import axios from "axios";
import _ from "lodash";

const CancelToken = axios.CancelToken;

const COLORS = {
  Free: "#FF8042",
  Professional: "#0088FE",
  Enterprise: "#00C49F",
  Undefined: "#a3a3a3",
};

class SubscriptionDistributionWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
    };
    this.axiosCancelFetchData = null;
  }

  fetchData = (startDate, endDate) => {
    this.setState({
      data: [],
      loading: true,
    });

    axios
      .get(
        process.env.REACT_APP_API_ROOT + "/dashboard/subscriptionDistribution",
        {
          params: {
            "period[start]": startDate.toDate(),
            "period[end]": endDate.toDate(),
          },
          headers: {
            Authorization: localStorage.getItem("jwt"),
          },
          cancelToken: new CancelToken((c) => {
            this.axiosCancelFetchData = c;
          }),
        }
      )
      .then(
        function (response) {
          let data = response.data.map((e, k) => ({
            plan: e._id != null ? e._id : "Undefined",
            count: e.count,
            color: COLORS[e._id != null ? e._id : "Undefined"],
          }));

          this.setState({
            data: data,
            loading: false,
          });
        }.bind(this)
      )
      .catch((e) => {
        if (!axios.isCancel(e)) {
          message.error("Could not fetch Subscription Distribution data");
        }
      });
  };

  componentDidMount() {
    const { startDate, endDate } = this.props;
    this.fetchData(startDate, endDate);
  }

  componentDidUpdate(prevProps) {
    const { startDate, endDate } = this.props;

    if (
      this.props.startDate !== prevProps.startDate ||
      this.props.endDate !== prevProps.endDate
    ) {
      this.fetchData(startDate, endDate);
    }
  }

  componentWillUnmount() {
    this.axiosCancelFetchData();
  }

  render() {
    return (
      <SubscriptionDistributionChart
        data={this.state.data}
        loading={this.state.loading}
      />
    );
  }
}

export default SubscriptionDistributionWrapper;
