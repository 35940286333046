import React, { Component } from "react";
import { Form, Icon, Input, Button, Checkbox, message, Avatar } from "antd";
import { withRouter } from "react-router-dom";
import axios from "axios";
import _ from "lodash";

class EnterCodeForm extends Component {
  state = {
    loading: false,
    fieldsDisabled: false,
  };

  doLogin = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log("Received values of form: ", values);
        this.setState({
          loading: true,
          fieldsDisabled: true,
          email: values.email,
        });
        axios({
          method: "post",
          url: process.env.REACT_APP_API_ROOT + "/login",
          headers: {
            "Content-Type": "application/json",
          },
          timeout: 20000,
          data: {
            email: this.props.email,
            code: values.code,
          },
        })
          .then((r) => {
            this.props.form.setFieldsValue({
              code: null,
            });
            console.log(r);
            const token = _.get(r, "data.data.token", false);

            if (token !== false) this.props.goToApp(token);
            else {
              message.error("Invalid server response. Please try again");
              this.setState({
                loading: false,
                fieldsDisabled: false,
              });
            }
          })
          .catch((r) => {
            if (r.response) {
              if (r.response.status == 400) {
                message.error("Invalid verification code");
              } else {
                message.error("Server error. Please try again");
              }
            } else if (r.code === "ECONNABORTED") {
              message.error("Server in not responding. Please try again");
            } else {
              message.error("Something went wrongs. Please try again");
            }

            this.setState({
              loading: false,
              fieldsDisabled: false,
            });
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const BackToLoginButton = withRouter(({ history }) => (
      <Button
        type="link"
        className="login-form-forgot"
        disabled={this.state.fieldsDisabled}
        onClick={() => {
          this.props.prev();
        }}
      >
        Back to login
      </Button>
    ));

    const SubmitButton = () => (
      <Button
        type="primary"
        htmlType="submit"
        loading={this.state.loading}
        onClick={this.doLogin}
        className="login-form-button"
      >
        Log in
      </Button>
    );

    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <h1 style={{ textTransform: "uppercase" }}>2 Factor Authentication</h1>
        <p>We have emailed you a verification code. Please check you inbox.</p>
        <Form.Item>
          {getFieldDecorator("code", {
            rules: [
              {
                required: true,
                message: "Please enter the verification code!",
              },
            ],
          })(
            <Input
              prefix={
                <Icon type="barcode" style={{ color: "rgba(0,0,0,.25)" }} />
              }
              disabled={this.state.fieldsDisabled}
              placeholder="Verification Code"
            />
          )}
        </Form.Item>
        <Form.Item>
          <BackToLoginButton />
          <SubmitButton />
        </Form.Item>
      </Form>
    );
  }
}

export default EnterCodeForm;
