import React, { Component } from "react";
import Statistics from "./Statistics";
import axios from "axios";
import { message } from "antd";
import moment from "moment";

const CancelToken = axios.CancelToken;

class StatisticsWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addedDevices: {},
      linkedDevices: {},
      addedEmployees: {},
      addedTransactions: {},
    };
    this.axiosCancelFetchData = null;
  }

  getChartData = (responseData) => {
    let chartData = { dates: [], values: [] };
    responseData
      .sort((a, b) => moment(a.date) - moment(b.date))
      // .forEach((e, i) => {
      // })
      .forEach((e, i) => {
        // e.date = moment(e.date).format("D MMM YYYY");
        chartData.dates.push(moment(e.date).format("D MMM YYYY"));
        chartData.values.push(e.count);
      });

    // chartData.dates = chartData.dates
    //   .sort((a, b) => a - b)
    //   .map((e, i) => {
    //     return e.format("D MMM YYYY");
    //   });

    return chartData;
  };

  fetchData = (startDate, endDate) => {
    // console.log(startDate.toDate(), endDate.toDate());

    this.setState({
      addedDevices: { count: 0, trend: null, loading: true },
      linkedDevices: { count: 0, trend: null, loading: true },
      addedEmployees: { count: 0, trend: null, loading: true },
      addedTransactions: { count: 0, trend: null, loading: true },
    });

    axios
      .get(process.env.REACT_APP_API_ROOT + "/dashboard/addedDevices", {
        params: {
          "period[start]": startDate.toDate(),
          "period[end]": endDate.toDate(),
        },
        headers: {
          Authorization: localStorage.getItem("jwt"),
        },
        cancelToken: new CancelToken((c) => {
          this.axiosCancelFetchData = c;
        }),
      })
      .then(
        function (response) {
          this.setState({
            addedDevices: {
              count: response.data.count,
              trend: response.data.trend,
              chartData: this.getChartData(response.data.data),
              loading: false,
            },
          });
        }.bind(this)
      )
      .catch((e) => {
        if (!axios.isCancel(e)) {
          message.error("Could not fetch Added Devices data");
        }
      });

    axios
      .get(process.env.REACT_APP_API_ROOT + "/dashboard/linkedDevices", {
        params: {
          "period[start]": startDate.toDate(),
          "period[end]": endDate.toDate(),
        },
        headers: {
          Authorization: localStorage.getItem("jwt"),
        },
        cancelToken: new CancelToken((c) => {
          this.axiosCancelFetchData = c;
        }),
      })
      .then(
        function (response) {
          this.setState({
            linkedDevices: {
              count: response.data.count,
              trend: response.data.trend,
              chartData: this.getChartData(response.data.data),
              loading: false,
            },
          });
        }.bind(this)
      )
      .catch((e) => {
        if (!axios.isCancel(e)) {
          message.error("Could not fetch Linked Devices data");
        }
      });

    axios
      .get(process.env.REACT_APP_API_ROOT + "/dashboard/addedEmployees", {
        params: {
          "period[start]": startDate.toDate(),
          "period[end]": endDate.toDate(),
        },
        headers: {
          Authorization: localStorage.getItem("jwt"),
        },
        cancelToken: new CancelToken((c) => {
          this.axiosCancelFetchData = c;
        }),
      })
      .then(
        function (response) {
          this.setState({
            addedEmployees: {
              count: response.data.count,
              trend: response.data.trend,
              chartData: this.getChartData(response.data.data),
              loading: false,
            },
          });
        }.bind(this)
      )
      .catch((e) => {
        if (!axios.isCancel(e)) {
          message.error("Could not fetch Added Employees data");
        }
      });

    axios
      .get(process.env.REACT_APP_API_ROOT + "/dashboard/addedTransactions", {
        params: {
          "period[start]": startDate.toDate(),
          "period[end]": endDate.toDate(),
        },
        headers: {
          Authorization: localStorage.getItem("jwt"),
        },
        cancelToken: new CancelToken((c) => {
          this.axiosCancelFetchData = c;
        }),
      })
      .then(
        function (response) {
          this.setState({
            addedTransactions: {
              count: response.data.count,
              trend: response.data.trend,
              chartData: this.getChartData(response.data.data),
              loading: false,
            },
          });
        }.bind(this)
      )
      .catch((e) => {
        if (!axios.isCancel(e)) {
          message.error("Could not fetch Transactions Count");
        }
      });
  };

  componentDidMount() {
    const { startDate, endDate } = this.props;
    this.fetchData(startDate, endDate);
  }

  componentDidUpdate(prevProps) {
    const { startDate, endDate } = this.props;

    if (
      this.props.startDate !== prevProps.startDate ||
      this.props.endDate !== prevProps.endDate ||
      this.props.key !== prevProps.key
    ) {
      this.fetchData(startDate, endDate);
    }
  }

  componentWillUnmount() {
    this.axiosCancelFetchData();
  }

  render() {
    return (
      <div>
        <Statistics
          addedDevices={this.state.addedDevices}
          linkedDevices={this.state.linkedDevices}
          addedEmployees={this.state.addedEmployees}
          addedTransactions={this.state.addedTransactions}
        />
      </div>
    );
  }
}

export default StatisticsWrapper;
