import React, { Component } from "react";
import { Card, Statistic, Icon } from "antd";
import ApexCharts from "react-apexcharts";
import numeral from "numeral";

class StatisticsCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          width: "100%",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          show: false,
        },

        series: [
          {
            name: "",
            data: [],
          },
        ],
        labels: [],
        xaxis: {
          type: "datetime",
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            show: false,
          },
        },
        yaxis: {
          show: false,
          crosshairs: {
            show: false,
          },
        },
        legend: {
          horizontalAlign: "left",
        },
      },
      series: [
        {
          name: "",
          data: [],
        },
      ],
    };
  }

  setChartState() {
    this.props.chartData !== undefined &&
      this.setState({
        options: {
          chart: {
            width: "100%",
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
          },
          grid: {
            show: false,
          },

          series: [
            {
              name: this.props.title,
              data: this.props.chartData.values,
            },
          ],
          labels: this.props.chartData.dates,
          xaxis: {
            type: "datetime",
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            crosshairs: {
              show: false,
            },
          },
          yaxis: {
            show: false,
            crosshairs: {
              show: false,
            },
          },
          legend: {
            horizontalAlign: "left",
          },
        },
        series: [
          {
            name: this.props.title,
            data: this.props.chartData.values,
          },
        ],
      });
  }

  componentDidMount = () => {
    this.setChartState();
  };

  componentDidUpdate(prevState) {
    if (prevState.chartData !== this.props.chartData) {
      this.setChartState();
    }
  }

  render() {
    const { title, amount, trend, chartData, loading } = this.props;
    return (
      <Card
        className="statistic-card orange"
        loading={loading}
        style={{ height: 158, position: "relative" }}
      >
        <Statistic
          title={title}
          value={numeral(amount).format("0a")}
          precision={0}
          className="main-number"
          style={{ position: "absolute", zIndex: 2, top: 15 }}
        />
        <Statistic
          className="sub-number"
          value={
            trend === null ? "---" : numeral(Math.abs(trend)).format("0.0a")
          }
          precision={2}
          valueStyle={{
            color: trend > 0 ? "#3f8600" : "#cf1322",
          }}
          prefix={
            trend === null ? (
              ""
            ) : trend > 0 ? (
              <Icon type="arrow-up" />
            ) : (
              <Icon type="arrow-down" />
            )
          }
          suffix="%"
          style={{ position: "absolute", zIndex: 2, bottom: 73, right: 10 }}
        />
        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            left: 0,
            zIndex: 1,
            height: 80,
            // overflow: "hidden"
          }}
        >
          {chartData !== undefined && (
            <ApexCharts
              options={this.state.options}
              series={this.state.series}
              type="area"
              height="110"
              width="100%"
            />
          )}
        </div>
      </Card>
    );
  }
}

export default StatisticsCard;
