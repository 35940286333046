import React, { Component } from "react";
import { Card } from "antd";
import _ from "lodash";
import numeral from "numeral";
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

class EmployeesAndUsersDistributionChart extends Component {
  render() {
    const { loading, data } = this.props;

    return (
      <Card title="Employees and Users per Country" loading={loading}>
        <ResponsiveContainer width="100%" height={450}>
          <BarChart
            data={data}
            margin={{ top: 5, right: 20, bottom: 5, left: -20 }}
          >
            <CartesianGrid stroke="#c9c9c9" strokeDasharray="3 3" />
            <XAxis dataKey="country" />
            <YAxis />
            <Tooltip
              cursor={{ fill: "rgba(238,238,238,0.5)" }}
              formatter={(value, name, props) => [
                numeral(value).format("0,0"),
                _.upperFirst(name),
              ]}
            />
            <Bar
              type="monotone"
              dataKey="employees"
              stroke="#135c8f"
              fill="#1f77b4"
            />
            <Bar
              type="monotone"
              dataKey="users"
              stroke="#9d4f0b"
              fill="#ff7f0e"
            />
          </BarChart>
        </ResponsiveContainer>
      </Card>
    );
  }
}

export default EmployeesAndUsersDistributionChart;
