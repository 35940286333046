import React, { Component } from "react";
import { Card } from "antd";
import {
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

class CompaniesChart extends Component {
  render() {
    const { data, loading } = this.props;

    return (
      <Card title="Registered Companies" loading={loading}>
        <ResponsiveContainer width="100%" height={250}>
          <AreaChart
            data={data}
            margin={{ top: 5, right: 20, bottom: 5, left: -20 }}
          >
            <CartesianGrid stroke="#eeeeee" strokeDasharray="2 2" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="value"
              stroke="#02afbb"
              fill="url(#colorUv)"
            />
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#00e6db" stopOpacity={0.99} />
                <stop offset="100%" stopColor="#02adba" stopOpacity={0.99} />
              </linearGradient>
            </defs>
          </AreaChart>
        </ResponsiveContainer>
      </Card>
    );
  }
}

export default CompaniesChart;
