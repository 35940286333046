import React, { Component } from "react";
import CompaniesChart from "./CompaniesChart";
import { message } from "antd";
import axios from "axios";
import _ from "lodash";

const CancelToken = axios.CancelToken;

class CompaniesChartWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      loading: false,
    };
    this.axiosCancelFetchData = null;
  }

  pad = (str, max) => {
    str = str.toString();
    return str.length < max ? this.pad("0" + str, max) : str;
  };

  fetchData = (startDate, endDate) => {
    this.setState({
      data: {},
      loading: true,
    });

    axios
      .get(process.env.REACT_APP_API_ROOT + "/dashboard/companiesCount", {
        params: {
          "period[start]": startDate.toDate(),
          "period[end]": endDate.toDate(),
        },
        headers: {
          Authorization: localStorage.getItem("jwt"),
        },
        cancelToken: new CancelToken((c) => {
          this.axiosCancelFetchData = c;
        }),
      })
      .then(
        function (response) {
          let days = (endDate - startDate) / (60 * 60 * 24 * 1000);
          let d = _.cloneDeep(startDate);
          let data = [];

          if (days > 90) {
            let dbResponse = [];
            response.data.map(
              (e, k) =>
                (dbResponse[this.pad(e._id.month, 2) + "-" + e._id.year] =
                  e.count)
            );
            while (d <= endDate) {
              data.push({
                date: d.format("MMM-YYYY"),
                value: dbResponse[d.format("MM-YYYY")] || 0,
              });
              d.add(1, "months");
            }
          } else {
            let dbResponse = [];
            response.data.map(
              (e, k) =>
                (dbResponse[
                  this.pad(e._id.day, 2) +
                    "-" +
                    this.pad(e._id.month, 2) +
                    "-" +
                    e._id.year
                ] = e.count)
            );
            let dd = _.cloneDeep(d).subtract(1, "days");
            for (let i = 0; i <= days; i++) {
              data.push({
                date: dd.add(1, "days").format("D-MMM"),
                value: dbResponse[dd.format("DD-MM-YYYY")] || 0,
              });
            }
          }
          this.setState({
            data: data,
            loading: false,
          });
        }.bind(this)
      )
      .catch((e) => {
        if (!axios.isCancel(e)) {
          message.error("Could not fetch Registered Companies data");
        }
      });
  };

  componentDidMount() {
    const { startDate, endDate } = this.props;
    this.fetchData(startDate, endDate);
  }

  componentDidUpdate(prevProps) {
    const { startDate, endDate } = this.props;

    if (
      this.props.startDate !== prevProps.startDate ||
      this.props.endDate !== prevProps.endDate
    ) {
      this.fetchData(startDate, endDate);
    }
  }

  componentWillUnmount() {
    this.axiosCancelFetchData();
  }

  render() {
    return (
      <CompaniesChart data={this.state.data} loading={this.state.loading} />
    );
  }
}

export default CompaniesChartWrapper;
