import React from "react";
import LoginScreen from "./screens/login/LoginScreen";
import ForgetPasswordScreen from "./screens/forget-password/ForgetPasswordScreen";
import AppLayout from "./screens/app/layout/AppLayout";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.css";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("jwt") ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/login" component={LoginScreen} />
          <Route
            exact
            path="/forgetpassword"
            component={ForgetPasswordScreen}
          />
          <Redirect exact from="/" to="/app" />
          <Redirect exact from="/app" to="/app/dashboard" />
          <PrivateRoute path="/app" component={AppLayout} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
