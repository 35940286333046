import React, { Component } from "react";
import EmployeesAndUsersDistributionChart from "./EmployeesAndUsersDistributionChart";
import { message } from "antd";
import axios from "axios";
import _ from "lodash";
const { getName } = require("country-list");

const CancelToken = axios.CancelToken;

class EmployeesAndUsersDistributionChartWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      loading: false,
    };
    this.axiosCancelFetchData = null;
  }

  fetchData = (startDate, endDate) => {
    this.setState({
      data: {},
      loading: true,
    });

    axios
      .get(
        process.env.REACT_APP_API_ROOT + "/dashboard/employeesUsersPerCountry",
        {
          params: {
            "period[start]": startDate.toDate(),
            "period[end]": endDate.toDate(),
          },
          headers: {
            Authorization: localStorage.getItem("jwt"),
          },
          cancelToken: new CancelToken((c) => {
            this.axiosCancelFetchData = c;
          }),
        }
      )
      .then(
        function (response) {
          //   let data = {};

          let data = response.data.map((e, i) => ({
            country: getName(e._id),
            employees: e.employeesCount,
            users: e.usersCount,
          }));

          this.setState({
            data: data,
            loading: false,
          });
        }.bind(this)
      )
      .catch((e) => {
        if (!axios.isCancel(e)) {
          message.error("Could not fetch Employees and Users per Country data");
        }
      });
  };

  componentDidMount() {
    const { startDate, endDate } = this.props;
    this.fetchData(startDate, endDate);
  }

  componentDidUpdate(prevProps) {
    const { startDate, endDate } = this.props;

    if (
      this.props.startDate !== prevProps.startDate ||
      this.props.endDate !== prevProps.endDate
    ) {
      this.fetchData(startDate, endDate);
    }
  }

  componentWillUnmount() {
    this.axiosCancelFetchData();
  }

  render() {
    return (
      <EmployeesAndUsersDistributionChart
        data={this.state.data}
        loading={this.state.loading}
      />
    );
  }
}

export default EmployeesAndUsersDistributionChartWrapper;
