import React, { Component } from "react";
import { Form, Icon, Input, Button, Checkbox, message, Avatar } from "antd";
import { withRouter } from "react-router-dom";
import axios from "axios";

class LoginForm extends Component {
  state = {
    loading: false,
    fieldsDisabled: false,
    username: "",
    email: null,
    password: null,
  };

  doLogin = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log("Received values of form: ", values);
        this.setState({
          loading: true,
          fieldsDisabled: true,
          email: values.email,
        });
        axios({
          method: "post",
          url: process.env.REACT_APP_API_ROOT + "/verify-login",
          headers: {
            "Content-Type": "application/json",
          },
          timeout: 20000,
          data: {
            email: values.email,
            password: values.password,
          },
        })
          .then((r) => {
            this.props.form.setFieldsValue({
              email: null,
              password: null,
            });
            this.props.next(values.email);
          })
          .catch((r) => {
            if (r.response) {
              if (r.response.status == 400) {
                message.error("Invalid email or password");
              } else {
                message.error("Server error. Please try again");
              }
            } else if (r.code === "ECONNABORTED") {
              message.error("Server in not responding. Please try again");
            } else {
              message.error("Something went wrongs. Please try again");
            }

            this.setState({
              loading: false,
              fieldsDisabled: false,
            });
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const ForgetPasswordButton = withRouter(({ history }) => (
      <Button
        type="link"
        className="login-form-forgot"
        disabled={this.state.fieldsDisabled}
        onClick={() => {
          history.push("/forgetpassword");
        }}
      >
        Forgot password
      </Button>
    ));

    const LoginButton = () => (
      <Button
        type="primary"
        htmlType="submit"
        loading={this.state.loading}
        onClick={this.doLogin}
        className="login-form-button"
      >
        Log in
      </Button>
    );

    if (this.state.username.length > 0) {
      return (
        <Form onSubmit={this.handleSubmit} className="login-form">
          <Avatar size={64} className="login-form-avatar">
            {this.state.username[0]}
          </Avatar>
          <h2 style={{ textTransform: "uppercase" }}>
            {this.state.username}, Welcome Back!
          </h2>
          <Form.Item>
            {getFieldDecorator("password", {
              rules: [
                { required: true, message: "Please enter your Password!" },
              ],
            })(
              <Input
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="password"
                disabled={this.state.fieldsDisabled}
                placeholder="Password"
              />
            )}
          </Form.Item>
          <Form.Item>
            <Button
              type="link"
              className="login-form-not-my-account"
              disabled={this.state.fieldsDisabled}
            >
              Not my account!
            </Button>
            <ForgetPasswordButton />
            <LoginButton />
          </Form.Item>
        </Form>
      );
    } else {
      return (
        <Form onSubmit={this.handleSubmit} className="login-form">
          <h1 style={{ textTransform: "uppercase" }}>Admin Login</h1>
          <Form.Item>
            {getFieldDecorator("email", {
              rules: [
                {
                  type: "email",
                  message: "Please enter a valid email address.",
                },
                { required: true, message: "Please enter your email!" },
              ],
            })(
              <Input
                prefix={
                  <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                disabled={this.state.fieldsDisabled}
                placeholder="Email"
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("password", {
              rules: [
                { required: true, message: "Please enter your Password!" },
              ],
            })(
              <Input
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="password"
                disabled={this.state.fieldsDisabled}
                placeholder="Password"
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("remember", {
              valuePropName: "checked",
              initialValue: true,
            })(
              <Checkbox
                disabled={this.state.fieldsDisabled}
                className="login-form-remember-me"
              >
                Remember me
              </Checkbox>
            )}
            <ForgetPasswordButton />
            <LoginButton />
          </Form.Item>
        </Form>
      );
    }
  }
}

export default LoginForm;
