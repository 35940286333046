import React, { Component } from "react";
import { Form, Icon, Input, Button, Row, Col } from "antd";

class VerificationStep extends Component {
  state = {
    loading: false,
    fieldsDisabled: false,
  };

  doLogin = () => {
    this.setState({ loading: true, fieldsDisabled: true });
    setTimeout(() => {
      this.setState({ loading: false, fieldsDisabled: false });
      //   message.error("Invalid email or password.");
      this.props.next();
    }, 2000);
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmit} className="verification-form">
        {/* <h1 style={{ textTransform: "uppercase" }}>Admin Login</h1> */}
        <p>
          Please check your inbox as we have emailed a verification code to you.
          It may take some minutes to get the code delivered to your inbox.
        </p>
        <Form.Item>
          {getFieldDecorator("code", {
            rules: [
              {
                required: true,
                message: "Please enter a valid verification code!",
              },
            ],
          })(
            <Input
              prefix={<Icon type="code" style={{ color: "rgba(0,0,0,.25)" }} />}
              disabled={this.state.fieldsDisabled}
              placeholder="Verification Code"
            />
          )}
        </Form.Item>
        <Form.Item>
          <Row>
            <Col span={6}>
              <Button
                type="link"
                disabled={this.state.fieldsDisabled}
                onClick={this.props.prev}
                className="verification-form-back-button"
              >
                Back
              </Button>
            </Col>
            <Col span={18}>
              <Button
                type="danger"
                htmlType="submit"
                loading={this.state.loading}
                onClick={this.doLogin}
                className="verification-form-button"
              >
                Next
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    );
  }
}

export default VerificationStep;
