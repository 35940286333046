import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  Layout,
  Menu,
  Icon,
  Avatar,
  //   Card,
  Tooltip,
  Button,
  Divider,
} from "antd";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
  Redirect,
} from "react-router-dom";
import Dashboard from "../dashboard/Dashboard";
import Error404 from "../errors/Error404";
import TableReport from "../table_report/TableReport";
import axios from "axios";
import jwt_decode from "jwt-decode";

const ButtonGroup = Button.Group;

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
// const { Meta } = Card;

const pagesKeys = ["dashboard", "report"];

class AppLayout extends Component {
  state = {
    collapsed: false,
    currentMenuItem: null,
    name: "",
  };

  constructor(props) {
    super(props);

    let that = this;

    const jwtToken = localStorage.getItem("jwt");
    const payload = jwt_decode(jwtToken);
    if (!payload.id || !payload.name) {
      this.props.history.push("/login");
    } else {
      this.state.name = payload.name;
    }

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response && error.response.status == 401) {
          this.props.history.push("/login");
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  onCollapse = (collapsed) => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  logout = () => {
    localStorage.removeItem("jwt");
    this.props.history.push("/login");
  };

  menuClick = (item) => {
    if (pagesKeys.indexOf(item.key) >= 0) {
      this.props.history.push("/app/" + item.key);
      this.setState({
        currentMenuItem: item.key,
      });
    } else {
      this.props.history.push("/app/404");
      this.setState({
        currentMenuItem: null,
      });
    }
  };

  componentDidMount() {
    if (this.props.location.pathname.indexOf("/app/") === 0) {
      const pageKey = this.props.location.pathname.split("/").pop();
      // console.log(pageKey);
      if (pagesKeys.indexOf(pageKey) >= 0) {
        this.setState({
          currentMenuItem: pageKey,
        });
      }
    } else {
      this.setState({
        currentMenuItem: null,
      });
    }
  }

  render() {
    return (
      <Layout
        style={{
          minHeight: "100vh",
        }}
      >
        <Sider
          collapsible
          collapsed={this.state.collapsed}
          onCollapse={this.onCollapse}
          //   theme="light"
          width={200}
        >
          <div
            className="logo"
            style={{
              height: 64,
              textAlign: "center",
              background: "#fff",
              borderRight: "1px solid #e1e1e1",
            }}
          >
            <img alt="AttendLab Logo" src="/images/attendlab-logo-64.png" />
          </div>
          <Menu
            theme="dark"
            defaultSelectedKeys={["1"]}
            mode="inline"
            selectedKeys={[this.state.currentMenuItem]}
            onClick={this.menuClick}
          >
            <Menu.Item key="dashboard">
              <Icon type="dashboard" />
              <span>Dashboard</span>
            </Menu.Item>
            <Menu.Item key="report">
              <Icon type="desktop" />
              <span>Table Report</span>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Header
            style={{
              background: "#fff",
              padding: 0,
              borderLeft: "1px solid #eee",
            }}
          >
            <div
              className="user"
              style={{
                lineHeight: "64px",
                marginRight: 10,
                textAlign: "right",
              }}
            >
              <Avatar style={{ marginRight: 5 }}>
                {this.state.name.slice(0, 1)}
              </Avatar>
              <span style={{ marginRight: 5 }}>{this.state.name}</span>
              <Divider type="vertical" />
              <ButtonGroup>
                <Tooltip placement="bottomRight" title="Account Settings">
                  <Button type="primary" icon="setting" disabled />
                </Tooltip>
                <Tooltip placement="bottomRight" title="Logout">
                  <Button type="danger" icon="poweroff" onClick={this.logout} />
                </Tooltip>
              </ButtonGroup>
            </div>
          </Header>
          <Content
            style={{
              margin: "0 16px",
              padding: "10px 0px 0px",
            }}
          >
            <Switch>
              <Route
                path={`${this.props.match.path}/dashboard`}
                component={Dashboard}
              />
              <Route
                path={`${this.props.match.path}/report`}
                component={TableReport}
              />
              <Route
                path={`${this.props.match.path}/404`}
                component={Error404}
              />
              <Route component={Error404} />
            </Switch>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            <p
              style={{
                marginTop: "13px",
              }}
            >
              Powered By{" "}
              <a href="https://hayasolutions.com" targrt="_BLANK">
                <img
                  src="/images/hayasolutions-logo.png"
                  alt="Haya Solutions Logo"
                  style={{
                    height: "30px",
                    marginTop: "-10px",
                  }}
                />
              </a>
            </p>
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

const AppLayoutWithRouter = withRouter(AppLayout);

export default AppLayoutWithRouter;
