import React, { Component } from "react";
import { Card } from "antd";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";

const { Meta } = Card;

const COLORS = ["#FF8042", "#0088FE", "#00C49F", "#FFBB28"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

class SubscriptionDistributionChart extends Component {
  render() {
    const { loading, data } = this.props;

    return (
      <div>
        <Card
          title="Subscription Distribution"
          style={{ position: "relative" }}
          loading={loading}
        >
          <Meta description="Chart is displaying the plans distribution for the companies created at the selected period."></Meta>
          <ResponsiveContainer width="100%" height={250}>
            <PieChart>
              <Pie
                data={data}
                dataKey="count"
                nameKey="plan"
                cx="50%"
                cy="50%"
                outerRadius={110}
                innerRadius={50}
                fill="#8884d8"
                labelLine={false}
                label={renderCustomizedLabel}
                // legendType="square"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
          <div className="legends">
            {data.map((entry, index) => (
              <div
                style={{ borderBottom: "1px solid #eee", marginBottom: 15 }}
                key={index}
              >
                <p style={{ lineHeight: "15px" }}>
                  <span
                    style={{
                      backgroundColor: entry.color,
                      display: "inline-block",
                      width: 15,
                      height: 15,
                      float: "left",
                      marginRight: 5,
                    }}
                  ></span>
                  <span>{entry.plan}</span>
                  <span style={{ float: "right" }}>{entry.count}</span>
                </p>
              </div>
            ))}
          </div>
        </Card>
      </div>
    );
  }
}

export default SubscriptionDistributionChart;
