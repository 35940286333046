import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import LoginForm from "./LoginForm";
import EnterCodeForm from "./EnterCodeForm";
import { Form, Row, Col, Card } from "antd";
import "./LoginStyle.css";

class LoginScreen extends Component {
  state = {
    enterCodeForm: 0,
    email: null,
  };

  next = (email) => {
    this.setState({ enterCodeForm: 1, email });
  };

  prev = () => {
    this.setState({ enterCodeForm: 0 });
  };

  goToApp = (token) => {
    localStorage.setItem("jwt", token);
    this.props.history.push("/app");
  };

  componentDidMount() {
    document.title = "Login | AttendLab Management";
    // console.log(process.env.REACT_APP_API_ROOT);
  }

  render() {
    const LoginFormANT = withRouter(
      Form.create({ name: "normal_login" })(LoginForm)
    );

    const EnterCodeFormANT = withRouter(
      Form.create({ name: "normal_login_enter_code" })(EnterCodeForm)
    );

    let CurrentForm = <LoginFormANT next={this.next} />;

    if (this.state.enterCodeForm == 1) {
      CurrentForm = (
        <EnterCodeFormANT
          email={this.state.email}
          prev={this.prev}
          goToApp={this.goToApp}
        />
      );
    }

    return (
      <div id="app-login">
        <Row
          type="flex"
          justify="center"
          align="middle"
          className="form-wrapper"
        >
          <Col span={12}>
            <Card
              className="form-card"
              cover={
                <div className="form-banner">
                  {/* AttendLab Logo */}
                  <img
                    alt="AttendLab Logo"
                    src="images/attendlab-logo.png"
                    className="attendlab-logo"
                  />
                  {/* Form Banner */}
                  <img
                    alt="AttendLab Login Banner"
                    src="images/login-banner.jpg"
                    className="form-banner-image"
                  />
                  <h3>Welcome to AttendLab Management Portal</h3>
                </div>
              }
            >
              {CurrentForm}
            </Card>
            <p
              style={{
                marginTop: "13px",
              }}
            >
              Powered By{" "}
              <a href="https://hayasolutions.com" targrt="_BLANK">
                <img
                  src="/images/hayasolutions-logo.png"
                  alt="Haya Solutions Logo"
                  style={{
                    height: "30px",
                    marginTop: "-10px",
                  }}
                />
              </a>
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default LoginScreen;
