import React, { Component } from "react";
import { Form, Icon, Input, Button, message } from "antd";
import axios from "axios";

class EnterEmailStep extends Component {
  state = {
    loading: false,
    fieldsDisabled: false,
  };

  doCheckEmail = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true, fieldsDisabled: true });

        axios({
          method: "post",
          url: process.env.REACT_APP_API_ROOT + "/users/forget-password",
          headers: {
            "Content-Type": "application/json",
          },
          timeout: 20000,
          data: {
            email: values.email,
          },
        })
          .then((r) => {
            this.props.form.setFieldsValue({
              email: null,
            });
            this.setState({ loading: false, fieldsDisabled: false });

            this.props.next(values.email);
          })
          .catch((r) => {
            if (r.response) {
              message.error("Invalid Email Address");
            } else if (r.code === "ECONNABORTED") {
              message.error("Server in not responding. Please try again");
            } else {
              message.error("Something went wrongs. Please try again");
            }

            this.setState({
              loading: false,
              fieldsDisabled: false,
            });
          });
      }
    });
    // this.setState({ loading: true, fieldsDisabled: true });
    // setTimeout(() => {
    //   this.setState({ loading: false, fieldsDisabled: false });
    //   //   message.error("Invalid email or password.");
    //   this.props.next();
    // }, 2000);
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmit} className="enteremail-form">
        {/* <h1 style={{ textTransform: "uppercase" }}>Admin Login</h1> */}
        <Form.Item>
          {getFieldDecorator("email", {
            rules: [{ required: true, message: "Please enter your email!" }],
          })(
            <Input
              prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
              disabled={this.state.fieldsDisabled}
              placeholder="Your Email Address"
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button
            type="danger"
            htmlType="submit"
            loading={this.state.loading}
            onClick={this.doCheckEmail}
            className="enteremail-form-button"
          >
            Next
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default EnterEmailStep;
