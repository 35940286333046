import React, { Component } from "react";
import { Col, Row } from "antd";
import StatisticsCard from "./StatisticsCard";

class Statistics extends Component {
  render() {
    const {
      addedDevices,
      linkedDevices,
      addedEmployees,
      addedTransactions,
    } = this.props;

    return (
      <div>
        <Row>
          <Col span={12}>
            <StatisticsCard
              title="Added Devices"
              amount={addedDevices.count}
              trend={addedDevices.trend}
              chartData={addedDevices.chartData}
              loading={addedDevices.loading}
            />
          </Col>
          <Col span={12}>
            <StatisticsCard
              title="Linked Devices"
              amount={linkedDevices.count}
              trend={linkedDevices.trend}
              chartData={linkedDevices.chartData}
              loading={linkedDevices.loading}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <StatisticsCard
              title="Added Employees"
              amount={addedEmployees.count}
              trend={addedEmployees.trend}
              chartData={addedEmployees.chartData}
              loading={addedEmployees.loading}
            />
          </Col>
          <Col span={12}>
            <StatisticsCard
              title="Transactions Count"
              amount={addedTransactions.count}
              trend={addedTransactions.trend}
              chartData={addedTransactions.chartData}
              loading={addedTransactions.loading}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Statistics;
