/* eslint-disable react/jsx-key */
import React, { Component } from "react";
import { Icon, Col, Row, DatePicker, Button, PageHeader } from "antd";
import moment from "moment";
import Statistics from "./components/statistics/StatisticsWrapper";
import CompaniesChart from "./components/companies_chart/CompaniesChartWrapper";
import SubscriptionDistributionChart from "./components/subscription_distribution/SubscriptionDistributionWrapper";
import EngagingCompaniesTable from "./components/most_engaging_companies/EngagingCompaniesTableWrapper";
import CompaniesMapDistribution from "./components/companies_map/CompaniesMapWrapper";
import EmployeesAndUsersDistributionChart from "./components/employees_users_per_country/EmployeesAndUsersDistributionChartWrapper";
// import faker from "faker";
import _ from "lodash";
// import axios from "axios";
import "./DashboardStyle.css";
import uniqid from "uniqid";

const { RangePicker } = DatePicker;

class Dashboard extends Component {
  constructor(props) {
    super(props);

    let date = [moment().subtract(10, "days"), moment()];
    let data = [];
    let days = (date[1] - date[0]) / (60 * 60 * 24 * 1000);
    for (let i = 0; i <= days; i++) {
      data.push({
        date: date[0].add(1, "days").format("D-MMM"),
        value: parseInt(Math.random() * (10 - 0 + 1), 10) + 0,
      });
    }

    this.state = {
      startDate: moment().subtract(6, "days").startOf("day"),
      endDate: moment().endOf("day"),
      statisticskey: uniqid(),
      companiesChartkey: uniqid(),
      subscriptionDistributionkey: uniqid(),
      companiesMapDistributionkey: uniqid(),
      engagingCompaniesTablekey: uniqid(),
      employeesAndUsersDistributionkey: uniqid(),
    };
  }

  reload = () => {
    this.setState({
      statisticskey: uniqid(),
      companiesChartkey: uniqid(),
      subscriptionDistributionkey: uniqid(),
      companiesMapDistributionkey: uniqid(),
      engagingCompaniesTablekey: uniqid(),
      employeesAndUsersDistributionkey: uniqid(),
    });
  };

  onChange = (date) => {
    // Switch To UTC Dates
    let startDate = moment.utc([
      date[0].year(),
      date[0].month(),
      date[0].date(),
      0,
      0,
      0,
      0,
    ]);
    let endDate = moment.utc([
      date[1].year(),
      date[1].month(),
      date[1].date(),
      0,
      0,
      0,
      0,
    ]);

    // Move Time To Boundaries
    endDate.endOf("day");
    startDate.startOf("day");

    this.setState({
      startDate: startDate,
      endDate: endDate,
    });
  };

  AllTimeStatistics = () => {
    this.onChange([moment("2019-01-01 00:00:00.000"), moment()]);
  };

  ThisMonthStatistics = () => {
    this.onChange([moment().startOf("month"), moment().endOf("month")]);
  };

  Last7DaysStatistics = () => {
    this.onChange([
      moment().subtract(6, "days").startOf("day"),
      moment().endOf("day"),
    ]);
  };

  ThisYearStatistics = () => {
    this.onChange([moment().startOf("year"), moment().endOf("year")]);
  };

  render() {
    return (
      <div>
        <PageHeader
          title="Dashboard"
          style={{ padding: "24px 0px" }}
          extra={[
            <Button
              type="link"
              onClick={this.AllTimeStatistics}
              disabled={this.state.disableDashboardInputs}
            >
              All Time
            </Button>,
            <Button
              type="link"
              onClick={this.ThisYearStatistics}
              disabled={this.state.disableDashboardInputs}
            >
              This Year
            </Button>,
            <Button
              type="link"
              onClick={this.ThisMonthStatistics}
              disabled={this.state.disableDashboardInputs}
            >
              This Month
            </Button>,
            <Button
              type="link"
              onClick={this.Last7DaysStatistics}
              disabled={this.state.disableDashboardInputs}
            >
              Last 7 Days
            </Button>,
            <RangePicker
              onChange={this.onChange}
              value={[this.state.startDate, this.state.endDate]}
              allowClear={false}
              disabled={this.state.disableDashboardInputs}
            />,
            <Button type="link" onClick={this.reload}>
              <Icon type="reload" />
              Refresh
            </Button>,
          ]}
        ></PageHeader>
        <Row>
          <Col span={12}>
            <Statistics
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              key={this.state.statisticskey}
            />
          </Col>

          <Col span={12}>
            <CompaniesChart
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              key={this.state.companiesChartkey}
            />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <SubscriptionDistributionChart
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              key={this.state.subscriptionDistributionkey}
            />
            <CompaniesMapDistribution
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              key={this.state.companiesMapDistributionkey}
            />
          </Col>
          <Col span={16}>
            <EngagingCompaniesTable
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              key={this.state.engagingCompaniesTablekey}
            />
            <EmployeesAndUsersDistributionChart
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              key={this.state.employeesAndUsersDistributionkey}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Dashboard;
