import React, { Component } from "react";
import ForgetPasswordSteps from "./ForgetPasswordSteps";
import { Row, Col, Card, Button } from "antd";
import { withRouter } from "react-router-dom";
import "./ForgetPasswordStyle.css";

const LoginButton = withRouter(({ history }) => (
  <Button
    type="ghost"
    className="back-to-login"
    onClick={() => {
      history.push("/login");
    }}
  >
    Login
  </Button>
));

const ForgetPasswordStepsWithRouter = withRouter(ForgetPasswordSteps);

class ForgetPasswordScreen extends Component {
  render() {
    return (
      <div id="app-forgetpassword">
        <Row
          type="flex"
          justify="center"
          align="middle"
          className="forgetpassword-wrapper"
        >
          <Col span={12}>
            <Card
              className="forgetpassword-card"
              cover={
                <div className="forgetpassword-banner">
                  {/* AttendLab Logo */}
                  <img
                    alt="AttendLab Logo"
                    src="images/attendlab-logo.png"
                    className="attendlab-logo"
                  />
                  {/* Form Banner */}
                  <img
                    alt="AttendLab Forget Password Banner"
                    src="images/forgetpassword-banner.jpg"
                    className="forgetpassword-banner-image"
                  />
                  <LoginButton style={{ float: "right" }} />
                  <h3>Reset Password</h3>
                </div>
              }
            >
              <ForgetPasswordStepsWithRouter />
            </Card>
            <p
              style={{
                marginTop: "13px",
              }}
            >
              Powered By{" "}
              <a href="https://hayasolutions.com" targrt="_BLANK">
                <img
                  src="/images/hayasolutions-logo.png"
                  alt="Haya Solutions Logo"
                  style={{
                    height: "30px",
                    marginTop: "-10px",
                  }}
                />
              </a>
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ForgetPasswordScreen;
